/* BODY */

body {
  margin: 0;
  padding: 0;
  min-width: 600px;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 231, 233);
}

.override-body {
  background-color: rgb(230, 231, 233);
}

/* LAYOUT */

.vertical-center {
  min-height: calc(100vh - 8rem);
  height: calc(100vh - 8rem);
}

/* SPINNER */

.little-image-spinner {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2.5em;
  height: 2.5em;
  border-radius: 1.5em;
  -webkit-animation: spin-grow 0.7s alternate-reverse infinite;
  -moz-animation: spin-grow 0.7s alternate-reverse infinite;
  animation: spin-grow 0.7s alternate-reverse infinite;
  z-index: 3;
}

.image-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  margin: -150px 0 0 -150px;
  border-radius: 150px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  z-index: 1;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-grow {
  100% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
}
@-webkit-keyframes spin-grow {
  100% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
}
@keyframes spin-grow {
  100% {
    -webkit-transform: rotate(360deg) scale(1.3);
    transform: rotate(360deg) scale(1.3);
  }
}

/* TOAST */

.toast {
  min-width: 15em;
  min-height: 5em;
  animation: signup-response 0.5s 1;
  -webkit-animation: signup-response 0.5s 1;
  animation-fill-mode: forwards;

  animation-delay: 6s;
  -webkit-animation-delay: 6s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}

@keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.toastContainer {
  z-index: 2000;
  position: fixed;
  top: 10px;
  right: 10px;
}

/* NAVBAR */

.col-navbar {
  height: 100vh;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.vertical-navbar {
  width: 50px;
  height: 100%;
  padding: 0;
  background: rgba(62, 44, 66, 1);
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vertical-navbar-active {
  width: 250px;
}

.navbar-group {
  display: block;
}

.navbar-menu-button {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #3e2c42;
  color: white;
  border: none;
  font-size: 20px;
  display: block;
}

.navbar-item {
  width: 170px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  overflow: hidden;
  display: block;
  position: relative;
  color: white;
}

.navbar-item-logo {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 20px;
  background: none;
  position: relative;
  display: inline-block;
}
.navbar-item-logo,
.navbar-item-logo::after {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all ease 0.3s;
}
.navbar-item-logo::before,
.navbar-item-logo::after {
  background: darkorange;
  content: '';
  position: absolute;
  z-index: -1;
}
/*.navbar-item:hover .navbar-item-logo {
  color: white;
}*/
.navbar-item-logo::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}
.navbar-item:hover .navbar-item-logo:after {
  width: 100%;
}

.navbar-item-title {
  margin: 0;
  padding: 0 0 0 7px;
  display: inline-block;
}
.navbar-item:hover .navbar-item-title {
  text-decoration: underline;
}

/* HEADER */

.page-header {
  padding: 7px 20px;
  background: linear-gradient(to right, #f29400, #df730b);
  color: white;
  box-shadow: 4px 4px 10px 2px lightgrey;
}

.page-section-title {
  padding: 7px 20px;
  color: #df730b;
}

.page-section {
  padding: 7px 20px;
  background-color: #f4f6fa;
}

/* USER EDIT LINK */

.userEditButton {
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background: none;
}
.userEditIcon {
  align-items: center;
  background-image: radial-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  border-radius: 16px;
  color: white;
  display: flex;
  height: 36px;
  justify-content: center;
  opacity: 0;
  padding: 2px 8px;
  position: absolute;
  transition: all ease 0.4s;
  width: 36px;
}
.userEditButton:hover .userEditIcon {
  opacity: 1;
  transition: all ease 0.4s;
}

/* UTIL */

.transform {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.button-arrow {
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
  border: none;
  outline: none;
  font-size: 3em;
}

.button-arrow-sm {
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
  border: none;
  outline: none;
  font-size: 1.5em;
}

.slide-left {
  -webkit-animation-name: slide-left; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.2s; /* Safari 4.0 - 8.0 */
  animation-name: slide-left;
  animation-duration: 0.2s;
}

.slide-right {
  -webkit-animation-name: slide-right; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.2s; /* Safari 4.0 - 8.0 */
  animation-name: slide-right;
  animation-duration: 0.2s;
}

@keyframes slide-right {
  to {
    transform: translateX(220px);
  }
}

@keyframes slide-left {
  to {
    transform: translateX(-220px);
  }
}

.content-left {
  text-align: left;
}

.content-center {
  text-align: center;
}

.content-right {
  text-align: right;
}

.slide-far-right {
  -webkit-animation: slide-far-right 25s linear infinite;
  animation: slide-far-right 25s linear infinite;
}

@keyframes slide-far-right {
  to {
    transform: translateX(100%);
  }
}

.elephant-animation {
  position: fixed;
  height: 75px;
  width: 120px;
  top: 400px;
  -webkit-animation: bounce 0.7s ease infinite;
  animation: bounce 0.7s ease infinite;
}

@keyframes bounce {
  50% {
    transform: translateY(-20px);
  }
}

.invisible-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.invisible-button:hover {
  text-decoration: underline;
}

.no-margin {
  margin: 0 !important;
}

button:focus {
  outline: none !important;
}

.task-name {
  cursor: pointer;
}

.task-name:hover {
  text-decoration: underline;
}

.carousel__slide-focus-ring {
  outline: none !important;
}
