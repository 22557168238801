/* Override bootstrap pour les couleurs des boutons*/
.btn-outline-primary {
  border: none;
  color: black !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: transparent;
  background-image: none;
  color: #df730b !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: rgb(230, 136, 0) !important;
  border-color: rgb(230, 136, 0) !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 136, 0, 0.5) !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: rgb(230, 136, 0) !important;
  border-color: rgb(230, 136, 0) !important;
}
.page-link {
  color: rgb(230, 136, 0) !important;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(230, 136, 0, 0.25) !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #e68800 !important;
  border-color: #e68800 !important;
}

.progress-bar {
  background-color: rgb(230, 136, 0) !important;
}

.btn-primary {
  color: #fff;
  background-color: #e68800;
  border-color: #e68800;
}

.btn-primary:hover {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 136, 0, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #e68800;
  border-color: #e68800;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #e68800;
  border-color: #e68800;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 136, 0, 0.5);
}
